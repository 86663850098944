import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from './search.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  searchText: string = "";
  lstSearch: any = [];
  isShow: boolean = true;
  selectedCountryCode: any = 196;
  lid = 1;
  pid = "";
  constructor(private search: SearchService, private route: ActivatedRoute, private router: Router) {
    let id = this.route.snapshot.queryParams['cid'];
    let lid = this.route.snapshot.queryParams['lid'];
    let pid = this.route.snapshot.queryParams['pid'];
    if (lid != undefined && !!lid){
      this.lid = parseInt(atob(lid));
    }
    else{
      let lid = atob(localStorage.getItem("lid"));
      this.lid = parseInt(lid);
    }
     
    if (id != undefined && !!id){
      this.selectedCountryCode = atob(id);
    } 

    if (pid != undefined && !!pid){
      this.pid = atob(pid);
    } 
    
   localStorage.setItem("cid",  btoa(this.selectedCountryCode));
   localStorage.setItem("lid",  btoa(this.lid.toString()));
  }

  ngOnInit(): void {     
    this.lstSearch = this.search.lstSearch;
    
    if( this.pid  == "gst"){
      let bkurl = "/reports/tax";
      this.router.navigate(['/organization/details']);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([bkurl]));
    }
    else{
      let bkurl =  this.router.url;
      this.router.navigate(['/organization/details']);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate([bkurl]));
    }
  }

  // ngAfterViewInit() {      
  //     let bkurl =  this.router.url;
  //     this.router.navigate(['/organization/details']);
  //     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
  //     this.router.navigate([bkurl]));
  // }

  SetCountry(id){ 
    localStorage.setItem("cid", btoa(id));
    let bkurl =  this.router.url;
    this.lstSearch = this.search.lstSearch;
    if (id == 196){
      let a = this.lstSearch.findIndex(x => x.url == './organization/maltaxrates');
      this.lstSearch[a].url = './organization/taxrates';

      let b = this.lstSearch.findIndex(x => x.url == './reports/maltax');
      this.lstSearch[b].url = './reports/tax';
    }
    else  if (id == 129){
      let a = this.lstSearch.findIndex(x => x.url == './organization/taxrates');
      this.lstSearch[a].url = './organization/maltaxrates';

      let b = this.lstSearch.findIndex(x => x.url == './reports/tax');
      this.lstSearch[b].url = './reports/maltax';
    }  
    
    if(bkurl == "/organization/tariffcodes" && id == 196){
      bkurl = "/organization/taxrates";
    }
    else  if(bkurl == "/organization/maltaxrates" && id == 196){
      bkurl = "/organization/taxrates";
    }
    else  if(bkurl == "/organization/taxrates" && id == 129){
      bkurl = "/organization/maltaxrates";
    }
    else  if(bkurl == "/reports/maltax" && id == 196){
      bkurl = "/reports/tax";
    }
    else  if(bkurl == "/reports/tax" && id == 129){
      bkurl = "/reports/maltax";
    }
    this.router.navigate(['/organization/details']);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>    
    this.router.navigate([bkurl]));
  }
}
