import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'organization',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../app/views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'organization',
        loadChildren: () => import('../app/views/organization/organization.module').then(m => m.OrganizationModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/views/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('../app/views/inventory/inventory.module').then(m => m.InventoryModule),
      },
      {
        path: 'purchase',
        loadChildren: () => import('../app/views/purchase/purchase.module').then(m => m.PurchaseModule),
      },
      {
        path: 'sales',
        loadChildren: () => import('../app/views/sales/sales.module').then(m => m.SalesModule),
      },
      {
        path: 'accounts',
        loadChildren: () => import('../app/views/accounts/accounts.module').then(m => m.AccountsModule),
      },
      {
        path: '',
        loadChildren: () => import('../app/views/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'banking',
        loadChildren: () => import('../app/views/banking/banking.module').then(m => m.BankingModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('../app/views/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'help',
        loadChildren: () => import('../app/views/help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'releasenotes',
        loadChildren: () => import('../app/views/release-notes/release-notes.module').then(m => m.ReleaseNotesModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
