import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    lstSearch: any = [];
    lstSearch1: any = [];
    lid = 1;
    constructor() {           
        let lid = atob(localStorage.getItem("lid"));
        this.lid = parseInt(lid);         
        this.lstSearch = [ 
            { title: "Organization", name: "Details", description: "Name, Legal, Logo", url: "./organization/details", keywords: "Organization details Name Legal Logo" },
            { title: "Organization", name: "Tax Rates", description: "List of view, Add, Edit, Delete, Tax components", url: "./organization/taxrates", keywords: "Organization taxrates List of view Add Edit Delete Tax components" },
            
            { title: "Settings", name: "Default", description: "Default, Payment Terms, Automatic Sequencing, E-Invoice Setting", url: "./settings/default", keywords: "Settings Default Payment Terms Automatic Sequencing E-Invoice Setting Default, Payment Terms, Automatic Sequencing, E-Invoice Setting" },
            { title: "Settings", name: "Finance", description: "General, Finance Year End, Currency, Time zone, Lock date, Tax Settings ", url: "./settings/finance", keywords: "Settings Finance General, Finance Year End, Currency, Time zone, Lock date, Tax" },
             { title: "Settings", name: "Templates", description: "Customization in Template Properties, Purchase Order, Bill, Quotation, Invoice, Credit Note, Customer Statement, Receipt", url: "./settings/templates", keywords: "Settings Templates Customization Properties  Purchase Order, Bill, Quotation, Invoice, Credit Note, Customer Statement, Receipt" },
            { title: "Settings", name: "Audit & Notes", description: "View the history & notes for the transaction with details", url: "./settings/historynotes", keywords: "Settings History Notes audit " },
            { title: "Settings", name: "Users & Roles", description: "Current User, Add User, User Invite Email, Active / Inactive users, Pending for activation, User Access Rights, Login History", url: "./settings/userroles", keywords: "Settings Users Roles Current User, Add User, User Invite Email, Active / Inactive users, Pending for activation, User Access Rights, Login History" },

            { title: "Inventory", name: "Items", description: "Add, Edit, View, Delete, Track, Opening stock, Unit, Quantity  Left, Average Cost, New Adjustment, Import", url: "./inventory/items", keywords: "Inventory Items Add, Edit, View, Delete, Track, Opening stock, Unit, Quantity  Left, Average Cost, New Adjustment import" },
           
            { title: "Purchase", name: "Purchase Order", description: "Create, View, Edit, Approve", url: "./purchase/purchaseorder", keywords: "Purchase Order Create, View, Edit, Approve" },
            { title: "Purchase", name: "Bills", description: "Create, View, Edit, Approve, Bill Payment, Allocate Credit to Bills", url: "./purchase/bills", keywords: "Purchase Bills Create, View, Edit, Approve Bill Payment, Allocate Credit to Bills" },
            { title: "Purchase", name: "Credit Notes", description: "Create, View, Edit, Approve, Refund Payment", url: "./purchase/creditnotes", keywords: "Purchase Credit Notes Create, View, Edit, Approve  Refund Payment" },

            { title: "Sales", name: "Quotation", description: "Create, View, Edit, Approve, Online Quotation", url: "./sales/quotation", keywords: "Sales Quotation Create, View, Edit, Approve, Online Quotation" },
            { title: "Sales", name: "Invoice", description: "Create, View, Edit, Approve, Receive Payment, Allocate a credit note to invoice", url: "./sales/invoice", keywords: "Sales Invoice Create, View, Edit, Approve,  Receive Payment, Allocate a credit note to invoice" },
            { title: "Sales", name: "Credit Notes", description: "Create, View, Edit, Approve, Make a cash Refund, Allocate Credit from Credit Note, Remove a credit note allocation", url: "./sales/creditnotes", keywords: "Sales Credit Notes Create, View, Edit, Approve, Make a cash Refund, Allocate Credit from Credit Note, Remove a credit note allocation" },

            { title: "Accounting", name: "Chart of Accounts", description: "Add, Edit", url: "./accounts/chartofaccounts", keywords: "Accounting Chart of Accounts Add, Edit" },
            { title: "Accounting", name: "Journal Entries", description: "Create, View, Edit, Post", url: "./accounts/journalentries", keywords: "Accounting Journal Entries Create, View, Edit, Post, Narration" },
            
            { title: "Contacts", name: "Contacts", description: "Add, Edit, View, Delete, Group, Statements", url: "./contact", keywords: "Contacts Add, Edit, View, Delete, Group, Statements" },

            { title: "Banking", name: "Banking Accounts", description: "Add/Edit automatic Feeds, No automatic Feeds", url: "../banking/bankingaccounts", keywords: "Banking Accounts Add/Edit automatic Feeds, No automatic Feeds" },
            { title: "Banking", name: "Spend Money", description: "Direct Payment, Prepayment, Overpayment", url: "../banking/spendmoney", keywords: "Banking Spend Money Direct Payment, Prepayment, Overpayment" },
            { title: "Banking", name: "Receive Money", description: "Direct Payment, Prepayment, Overpayment", url: "../banking/receivemoney", keywords: "Banking Receive Money Direct Payment, Prepayment, Overpayment" },
            { title: "Banking", name: "Transfer Money", description: "Bank Spend Money/Receive money/Transfer money Rules, Account Transaction, Import Bank Statement, Cash Coding", url: "../banking/transfermoney", keywords: "Banking Transfer Money Bank Spend Money/Receive money/Transfer money Rules, Account Transaction, Import Bank Statement, Cash Coding" },
            { title: "Banking", name: "Reconcile", description: "Transfer, Find and Create", url: "../banking/reconcile", keywords: "Banking Reconcile Transfer, Find and Create" },
            { title: "Banking", name: "Stripe", description: "", url: "../banking/stripe", keywords: "Banking Stripe" },
            { title: "Banking", name: "Paypal", description: "", url: "../banking/paypal", keywords: "Banking Paypal" },

            { title: "Reports", name: "Finance", description: "Balance Sheet", url: "./reports/finance", keywords: "Reports Finance Balance Sheet" },
            { title: "Reports", name: "Inventory", description: "Item Summary, Item Detail, Item List", url: "./reports/inventory", keywords: "Reports Inventory Item Summary, Item Detail, Item List" },
            { title: "Reports", name: "Purchase", description: "Payable Invoice Detail, Payable Invoice Summary, Aged Payables Summary, Aged Payables Details, Purchase Analysis", url: "./reports/purchase", keywords: "Reports Purchase Payable Invoice Detail, Payable Invoice Summary, Aged Payables Summary, Aged Payables Details, Purchase Analysis" },
            { title: "Reports", name: "Sales", description: "Receivable Invoice Detail, Receivable Invoice Summary, Aged Receivables Summary, Aged Receivables Detail, Sales Analysis", url: "./reports/sales", keywords: "Reports Sales Receivable Invoice Detail, Receivable Invoice Summary, Aged Receivables Summary, Aged Receivables Detail, Sales Analysis" },
            { title: "Reports", name: "Tax", description: "Sales Tax Report", url: "./reports/tax", keywords: "Reports Tax Sales Tax Report" },
            { title: "Reports", name: "Profit and Loss", description: "Profit and Loss", url: "./reports/profitandloss", keywords: "Reports Finance Profit and Loss" },
        ]
        
        if(this.lid != 6){
            this.lstSearch.push(
                { title: "Organization", name: "Currencies", description: "List of view, Add, Edit, Set custom exchange rate", url: "./organization/currencies", keywords: "Organization currencies List of view Add Edit Set custom exchange rate" },
                { title: "Settings", name: "Email", description: "Mail server, Enable Custom Email server, Email Template", url: "./settings/email", keywords: "Settings Email Mail server, Enable Custom Email server, Email Template" },
                { title: "Inventory", name: "Price List", description: "Add Price List ,Assign Prie List,Update Prie List in transaction", url: "./inventory/pricelist", keywords: "Add Price List ,Assign Prie List,Update Prie List in transaction" },
                { title: "Inventory", name: "Warehouse", description: "Create a Warehouse,Change Primary Warehouse,From warehouse settings,Warehouse Tracking Status, Detail Report", url: "./inventory/warehouse", keywords: "Create a Warehouse,Change Primary Warehouse,From warehouse settings,Warehouse Tracking Status, Detail Report" },
                { title: "Inventory", name: "Stock Transfer", description: "Create a Stock Transfer Batch/Serial", url: "./inventory/stocktransfer", keywords: "Create a Stock Transfer Batch/Serial" },
                { title: "Accounting", name: "Budget", description: "Create, View, Edit, Delete, Budget vs Actual Comparison ", url: "./accounts/budget", keywords: "Accounting Budget Create, View, Edit, Delete, Budget vs Actual, Comparison" },
            );            
        }
        
    }
}
