<header class="header fixed-top">
  <div class="branding docs-branding">
    <div class="container-fluid position-relative py-2">
      <div class="docs-logo-wrapper">
        <button id="docs-sidebar-toggler" class="docs-sidebar-toggler docs-sidebar-visible me-2 d-xl-none"
          type="button">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="site-logo"><a class="navbar-brand" href="index.html">
            <img class="logo-icon me-2" *ngIf="lid == 6" src="assets/images/digisme-logo.png" alt="logo" width="80"  height="18">
            <img class="logo-icon me-2" *ngIf="lid != 6" src="assets/images/Info-Tech-Logo_sm.png" alt="logo">
            <span class="logo-text">
              <span class="text-alt">Support</span>
            </span>
          </a>
        </div>
      </div>
      <div class="docs-top-utilities d-flex justify-content-end align-items-top">
        <div class="nav-item mi-language">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
            aria-expanded="false" title="Country">
            <span *ngIf="selectedCountryCode == 129"> <img src="assets/images/flags/fl-my.png" alt="MALAYSIA" />
              MALAYSIA
            </span>
            <span *ngIf="selectedCountryCode == 196"> <img src="assets/images/flags/fl-sg.png" alt="SINGAPORE" />
              SINGAPORE
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a *ngIf="lid != 6" class="dropdown-item" href="javascript:void(0)" (click)="selectedCountryCode = 129; SetCountry(129);" title="Malaysia">
              <img src="assets/images/flags/fl-my.png" alt="MALAYSIA" />
              MALAYSIA
            </a>
            <a class="dropdown-item" href="javascript:void(0)" title="Singapore" (click)="selectedCountryCode = 196;SetCountry(196);">
              <img src="assets/images/flags/fl-sg.png" alt="SINGAPORE" />
              SINGAPORE
            </a>
          </div>
        </div>


        <div class="top-search-box d-none d-lg-flex">
          <div class="search-form">
            <input type="text" class="form-control" id="search-text" aria-describedby="search-text"
              (focus)='isShow=true' [(ngModel)]="searchText" placeholder="Enter text to search" autofocus />
            <button onClick="search(id)" id="button" class="btn search-btn">
              <i class="fas fa-search"></i>
            </button>
            <ul class="list-group list-group-flush" *ngIf="searchText != '' && isShow">
              <li class="list-group-item"
                *ngFor="let c of lstSearch | appFilter: searchText | slice: 0-5">
                <a class="dropdown-item text-wrap" (click)="isShow=false" routerLink="{{c.url}}">
                  <b>{{c.name}}</b>
                  <small> {{c.title}}</small>
                  <div><small>{{c.description}}</small></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="docs-wrapper">
  <div id="docs-sidebar" class="docs-sidebar sidebar-visible">
    <nav id="docs-nav" class="docs-nav navbar">
      <ul class="section-items list-unstyled nav flex-column pb-3">
        <!-- Organization -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-building"></i>
            </span>
            Organization
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./organization/details">
            Details
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" *ngIf="selectedCountryCode == '196'" routerLink="./organization/taxrates">
            Tax Rates
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" *ngIf="selectedCountryCode == '129'" routerLink="./organization/maltaxrates">
            Tax Rates
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" *ngIf="selectedCountryCode == '129'" routerLink="./organization/tariffcodes">
            Tariff Code
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./organization/currencies">
            Currencies
          </a>
        </li>
        <!-- Organization -->

        <!-- Settings -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-2">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-cogs"></i>
            </span>
            Settings
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/default">
            Default Settings
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/finance">
            Finance Settings
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./settings/email">
            Email Settings
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./settings/customfield">
            Custom Field
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/reminder">
            Reminder
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/templates">
            Templates
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./settings/documents">
            Documents
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/userroles">
            Users & Roles
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./settings/tools">
            Tools
          </a>
        </li>
        <!-- Settings -->

        <!-- Inventory -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-3">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-box"></i>
            </span>
            Inventory
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./inventory/items">
            Items
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./inventory/pricelist">
            Price List
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./inventory/warehouse">
            Warehouse
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./inventory/stocktransfer">
            Stock Transfer
          </a>
        </li>
        <!-- Inventory -->

        <!-- Purchase -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-4">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-shopping-bag "></i>
            </span>
            Purchase
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./purchase/purchaseorder">
            Purchase Order
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./purchase/bills">
            Bills
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./purchase/creditnotes">
            Credit Notes
          </a>
        </li>
        <!-- Purchase -->

        <!-- Sales -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-5">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-shopping-cart"></i>
            </span>
            Sales
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./sales/quotation">
            Quotation
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./sales/invoice">
            Invoice
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./sales/creditnotes">
            Credit Notes
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./sales/deliveryorder">
            Delivery Order
          </a>
        </li>
        <!-- Sales -->

        <!-- Accounting -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-6">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-server"></i>
            </span>
            Accounting
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./accounts/chartofaccounts">
            Chart of Accounts
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./accounts/journalentries">
            Journal Entries
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./accounts/migration">
            Migration
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./accounts/budget">
            Budget
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./accounts/fixedasset">
            Fixed Asset
          </a>
        </li>
        <!-- Accounting -->

        <!-- Contacts -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" routerLink="./contact" href="#section-7">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-users"></i>
            </span>
            Contacts
          </a>
        </li>
        <!-- Contacts -->

        <!-- Banking -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-8">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-university"></i>
            </span>
            Banking
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/bankingaccounts">
            Banking Accounts
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/spendmoney">
            Spend Money
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/receivemoney">
            Receive Money
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/transfermoney">
            Transfer Money
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/reconcile">
            Reconcile
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/stripe">
            Stripe
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./banking/paypal">
            Paypal
          </a>
        </li>
        <!-- Banking -->

        <!-- Reports -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" href="#section-9">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-lightbulb"></i>
            </span>
            Reports
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./reports/finance" href="#item-9-1">
            Finance
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./reports/inventory" href="#item-9-2">
            Inventory Reports
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./reports/purchase" href="#item-9-3">
            Purchase Reports
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./reports/sales" href="#item-9-4">
            Sales
            Reports
          </a>
        </li>
        <li class="nav-item" *ngIf="selectedCountryCode == '196'">
          <a class="nav-link scrollto" routerLink="./reports/tax" href="#item-9-5">
            Tax Reports
          </a>
        </li>
        <li class="nav-item" *ngIf="selectedCountryCode == '129'">
          <a class="nav-link scrollto" routerLink="./reports/maltax" href="#item-9-2">
            Tax Reports
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link scrollto" routerLink="./reports/profitandloss" href="#item-9-6">
            Profit and Loss Report
          </a>
        </li>
        <li class="nav-item" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./reports/financialperformance" href="#item-9-6">
            Financial Performance
          </a>
        </li>
        <!-- Reports -->

        <!-- Help -->
        <li class="nav-item section-title mt-3">
          <a class="nav-link scrollto" routerLink="./help" href="#section-10">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-question-circle"></i>
            </span>
            Help
          </a>
        </li>
        <!-- Help -->

        <!-- Release Notes -->
        <li class="nav-item section-title mt-3" *ngIf="lid != 6">
          <a class="nav-link scrollto" routerLink="./releasenotes" href="#section-11">
            <span class="theme-icon-holder me-2">
              <i class="fas fa-upload"></i>
            </span>
            Release Notes
          </a>
        </li>
        <!-- Release Notes -->
      </ul>

    </nav>
  </div>
  <div class="docs-content" id="text">
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>